import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Appointment from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Leistungen from '@solid-ui-blocks/Features/Block02'
import Ablauf from '@solid-ui-blocks/Features/Block03'
import Kundenmeinungen from '@solid-ui-blocks/Testimonials/Block03'
import Unternehmen from '@solid-ui-blocks/Companies/Block01'
import Stats from '@solid-ui-blocks/Stats/Block01'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
import useSiteMetadata from '@blocks-helpers/useSiteMetadata'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  const site = useSiteMetadata()
  const schema =

  {
    "@context": "https://schema.org",
    "@graph":
      [
        {
          "@type": "WebPage",
          "@id": "https://www.bitconcepts.de/",
          "url": "https://www.bitconcepts.de/",
          "name": "Digitalmarketing aus Cloppenburg | Bitconcepts",
          "isPartOf": { "@id": "https://www.bitconcepts.de/#website" },
          "about": { "@id": "https://www.bitconcepts.de/#organization" },
          "primaryImageOfPage": { "@id": "https://www.bitconcepts.de/#primaryimage" },
          "image": { "@id": "https://www.bitconcepts.de/#primaryimage" },
          "thumbnailUrl": "https://bitconcepts.de/static/82275983ca2245e1976573fbbe2f867d/a067c/logo.webp",
          "description": site.description,
          "breadcrumb": { "@id": "https://www.bitconcepts.de/#breadcrumb" },
          "inLanguage": "de-DE",
          "potentialAction":
            [{ "@type": "ReadAction", "target": ["https://www.bitconcepts.de/"] }]
        },
        {
          "@type": "BreadcrumbList",
          "@id": "https://www.bitconcepts.de/#breadcrumb",
          "itemListElement":
            [{
              "@type": "ListItem",
              "position": 1,
              "name": "Startseite"
            }]
        },
        {
          "@type": "WebSite",
          "@id": "https://www.bitconcepts.de/#website",
          "url": "https://www.bitconcepts.de/",
          "name": "Bitconcepts",
          "description": site.description,
          "publisher": { "@id": "https://www.bitconcepts.de/#organization" },
          "inLanguage": "de-DE",
        },
        {
          "@type": "Place",
          "@id": "https://bitconcepts.de/#place",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Höltinghauser Ring 4",
            "addressLocality": "Cloppenburg",
            "addressRegion": "Niedersachsen",
            "postalCode": "49661",
            "addressCountry": "Deutschland"
          }
        },
        {
          "@type":"Product",
          "name":"Bitconcepts",
          "description":"Digitalmarketing, Suchmaschinenwerbung, Webdesign, Social Media und mehr",
          "aggregateRating":{"@type":"AggregateRating","reviewCount":9,"ratingValue":5,"bestRating":5,"worstRating":1},
          "image":"https://bitconcepts.de/static/82275983ca2245e1976573fbbe2f867d/a067c/logo.webp"}		
      ]
  }

  return (
    <Layout theme={theme} {...props}>
      <Seo
        title='Digitalmarketing aus Cloppenburg | Bitconcepts'
        description='Bitconcepts - Agentur für Digitalmarketing, Suchmaschinenwerbung, Webdesign, Social Media und mehr. Erfahre mehr!'
        schemaMarkup={schema}
      />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <Appointment />
      {/* Blocks */}
      <Header content={content['header-light']} menuJustify='space-between' />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      {/*<Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={3} variant='dots' position='bottom' arrows>
          <Screenshot content={content['screenshot-one']} />
          <Screenshot content={content['screenshot-two']} />
          <Screenshot content={content['screenshot-three']} />
        </Tabs>
      </Container>*/}
      <Divider space='5' />
      <Divider space='5' />
      <Ablauf content={content['ablauf']} />
      <Divider space='5' />
      <Container variant='full' sx={styles.featuresContainer}>
        <Leistungen content={content['leistungen']} />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='full' sx={styles.socialProofContainer}>
        <Divider space='6' />
        <Divider space='-6' />
        <Container variant='full' sx={styles.strategiesContainer}>
          <Divider space={-6} />
          <Stats content={content['stats']} />
        </Container>
        <Divider space='5' />
        <Divider space='5' />
        <Kundenmeinungen content={content['kundenmeinungen']} />
        <Divider space='5' />
      </Container>
      <Divider space='5' />
      <Unternehmen content={content['unternehmen']} />
      <Divider space='5' />
      <Container variant='narrow'>
        <Faq content={content['faq']}/>
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageSaasV2BlockContentAndHomepageSaasV2BlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
